const paywall = {
    applications: 'Limite de {{count, number}} aplicação do plano <planName/> atingido. Atualize o plano para atender às necessidades da sua equipa. Para obter qualquer ajuda, sinta-se à vontade para <a>entrar em contacto connosco</a>.',
    applications_other: 'Limite de {{count, number}} aplicações do plano <planName/> atingido. Atualize o plano para atender às necessidades da sua equipa. Para obter qualquer ajuda, sinta-se à vontade para <a>entrar em contacto connosco</a>.',
    machine_to_machine_feature: 'Mude para o plano <strong>Pro</strong> para obter aplicações adicionais de máquina a máquina e aproveitar todos os recursos premium. <a>Entre em contacto connosco</a> se tiver dúvidas.',
    machine_to_machine: 'Limite de {{count, number}} aplicação de máquina a máquina do plano <planName/> atingido. Atualize o plano para atender às necessidades da sua equipa. Para obter qualquer ajuda, sinta-se à vontade para <a>entrar em contacto connosco</a>.',
    machine_to_machine_other: 'Limite de {{count, number}} aplicações de máquina a máquina do plano <planName/> atingido. Atualize o plano para atender às necessidades da sua equipa. Para obter qualquer ajuda, sinta-se à vontade para <a>entrar em contacto connosco</a>.',
    resources: 'Atingiu o limite de {{count, number}} recursos de API do plano <planName/>. Atualize o plano para satisfazer as necessidades da sua equipa. <a>Contacte-nos</a> se precisar de assistência.',
    resources_other: 'Atingiu o limite de {{count, number}} recursos de API do plano <planName/>. Atualize o plano para satisfazer as necessidades da sua equipa. <a>Contacte-nos</a> se precisar de assistência.',
    scopes_per_resource: 'Atingiu o limite de {{count, number}} permissões por recurso de API do plano <planName/>. Atualize agora para expandir. <a>Contacte-nos</a> se precisar de assistência.',
    scopes_per_resource_other: 'Atingiu o limite de {{count, number}} permissões por recurso de API do plano <planName/>. Atualize agora para expandir. <a>Contacte-nos</a> se precisar de assistência.',
    custom_domain: 'Desbloqueie a funcionalidade de domínio personalizado ao atualizar para o plano <strong>Hobby</strong> ou <strong>Pro</strong>. Não hesite em <a>entrar em contacto connosco</a> se precisar de qualquer assistência.',
    social_connectors: 'Atingiu o limite de {{count, number}} conectores sociais do plano <planName/>. Atualize o plano para obter conectores sociais adicionais e a capacidade de criar os seus próprios conectores usando os protocolos OIDC, OAuth 2.0 e SAML. Não hesite em <a>Contacte-nos</a> se precisar de ajuda.',
    social_connectors_other: 'Atingiu o limite de {{count, number}} conectores sociais do plano <planName/>. Atualize o plano para obter conectores sociais adicionais e a capacidade de criar os seus próprios conectores usando os protocolos OIDC, OAuth 2.0 e SAML. Não hesite em <a>Contacte-nos</a> se precisar de ajuda.',
    standard_connectors_feature: 'Upgrade para os planos <strong>Hobby</strong> ou <strong>Pro</strong> para criar os seus próprios conectores usando os protocolos OIDC, OAuth 2.0 e SAML, além de conectores sociais ilimitados e todos os recursos premium. Sinta-se à vontade para <a>entrar em contacto connosco</a> se precisar de qualquer assistência.',
    standard_connectors: 'Atingiu o limite de {{count, number}} conectores sociais do plano <planName/>. Atualize o plano para obter conectores sociais adicionais e a capacidade de criar os seus próprios conectores usando os protocolos OIDC, OAuth 2.0 e SAML. Não hesite em <a>Contacte-nos</a> se precisar de ajuda.',
    standard_connectors_other: 'Atingiu o limite de {{count, number}} conectores sociais do plano <planName/>. Atualize o plano para obter conectores sociais adicionais e a capacidade de criar os seus próprios conectores usando os protocolos OIDC, OAuth 2.0 e SAML. Não hesite em <a>Contacte-nos</a> se precisar de ajuda.',
    standard_connectors_pro: 'Atingiu o limite de {{count, number}} conectores padrão do plano <planName/>. Atualize para o plano Empresarial para obter conectores sociais adicionais e a capacidade de criar os seus próprios conectores usando os protocolos OIDC, OAuth 2.0 e SAML. Não hesite em <a>Contacte-nos</a> se precisar de ajuda.',
    standard_connectors_pro_other: 'Atingiu o limite de {{count, number}} conectores padrão do plano <planName/>. Atualize para o plano Empresarial para obter conectores sociais adicionais e a capacidade de criar os seus próprios conectores usando os protocolos OIDC, OAuth 2.0 e SAML. Não hesite em <a>Contacte-nos</a> se precisar de ajuda.',
    roles: 'Atingiu o limite de {{count, number}} funções do plano <planName/>. Atualize o plano para adicionar funções e permissões adicionais. Não hesite em <a>Contacte-nos</a> se precisar de ajuda.',
    roles_other: 'Atingiu o limite de {{count, number}} funções do plano <planName/>. Atualize o plano para adicionar funções e permissões adicionais. Não hesite em <a>Contacte-nos</a> se precisar de ajuda.',
    machine_to_machine_roles: '{{count, number}} papel de máquina a máquina do plano <planName/> atingido. Atualize o plano para adicionar papéis e permissões adicionais. Sinta-se à vontade para <a>entrar em contacto connosco</a> se precisar de ajuda.',
    machine_to_machine_roles_other: '{{count, number}} papéis de máquina a máquina do plano <planName/> atingidos. Atualize o plano para adicionar papéis e permissões adicionais. Sinta-se à vontade para <a>entrar em contacto connosco</a> se precisar de ajuda.',
    scopes_per_role: 'Atingiu o limite de {{count, number}} permissões por função do plano <planName/>. Atualize o plano para adicionar funções e permissões adicionais. Não hesite em <a>Contacte-nos</a> se precisar de ajuda.',
    scopes_per_role_other: 'Atingiu o limite de {{count, number}} permissões por função do plano <planName/>. Atualize o plano para adicionar funções e permissões adicionais. Não hesite em <a>Contacte-nos</a> se precisar de ajuda.',
    saml_applications_oss: 'A aplicação SAML adicional está disponível com o plano Logto Empresarial. Contacte-nos se precisar de assistência.',
    logto_pricing_button_text: 'Preços do Logto Cloud',
    saml_applications: 'A aplicação SAML adicional está disponível com o plano Logto Empresarial. Contacte-nos se precisar de assistência.',
    hooks: 'Atingiu o limite de {{count, number}} webhooks do plano <planName/>. Atualize o plano para criar mais webhooks. Não hesite em <a>Contacte-nos</a> se precisar de ajuda.',
    hooks_other: 'Atingiu o limite de {{count, number}} webhooks do plano <planName/>. Atualize o plano para criar mais webhooks. Não hesite em <a>Contacte-nos</a> se precisar de ajuda.',
    mfa: 'Desbloqueie o MFA para a verificação de segurança ao atualizar para um plano pago. Não hesite em <a>entrar em contacto connosco</a> se precisar de assistência.',
    organizations: 'Desbloquear organizações ao atualizar para um plano pago. Não hesite em <a>entrar em contacto connosco</a> se precisar de assistência.',
    third_party_apps: 'Desbloqueie o Logto como IdP para aplicações de terceiros ao atualizar para um plano pago. Para qualquer assistência, sinta-se à vontade para <a>contactar-nos</a>.',
    sso_connectors: 'Desbloqueie o SSO empresarial ao atualizar para um plano pago. Para qualquer assistência, sinta-se à vontade para <a>contactar-nos</a>.',
    tenant_members: 'Desbloqueie a funcionalidade de colaboração ao atualizar para um plano pago. Para qualquer assistência, sinta-se à vontade para <a>contactar-nos</a>.',
    tenant_members_dev_plan: 'Chegou ao seu limite de {{limit}} membros. Liberte um membro ou revoque um convite pendente para adicionar alguém novo. Precisa de mais lugares? Sinta-se à vontade para contactar-nos.',
    custom_jwt: {
        title: 'Adicionar alegações personalizadas',
        description: 'Atualize para um plano pago para funcionalidade personalizada JWT e benefícios premium. Não hesite em <a>contactar-nos</a> se tiver alguma dúvida.',
    },
    bring_your_ui: 'Atualize para um plano pago para trazer a funcionalidade da sua própria UI personalizada e benefícios premium.',
};
export default Object.freeze(paywall);
