const user_scopes = {
    descriptions: {
        custom_data: 'Os teus dados personalizados',
        email: 'O teu endereço de e-mail',
        phone: 'O teu número de telefone',
        profile: 'O teu nome, nome de utilizador, avatar e outras informações pessoais',
        roles: 'Os teus papéis',
        identities: 'As tuas identidades sociais associadas',
        'urn:logto:scope:organizations': 'As tuas informações de organização',
        'urn:logto:scope:organization_roles': 'Os teus papéis na organização',
        address: 'A tua morada',
    },
};
export default Object.freeze(user_scopes);
